/* Gotham Normal */
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Book.otf');
	font-weight: 400;
	font-style: normal;
}

/* Gotham Bold */
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Bold.otf');
	font-weight: 700;
	font-style: normal;
}

/* Gotham Normal Italic*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-BookItalic.otf');
	font-weight: 400;
	font-style: italic;
}

/* Gotham Light*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Thin.otf');
	font-weight: 300;
	font-style: normal;
}

/* Gotham SemiLight*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Light.otf');
	font-weight: 350;
	font-style: normal;
}

/* Gotham Bold Italic*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-BoldItalic.otf');
	font-weight: 700;
	font-style: italic;
}

/* Gotham Black*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Black.otf');
	font-weight: 900;
	font-style: normal;
}

/* Gotham Black Italic*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-BlackItalic.otf');
	font-weight: 900;
	font-style: italic;
}

/* Gotham Light Italic*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-ThinItalic.otf');
	font-weight: 300;
	font-style: italic;
}

/* Gotham SemiBold*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Medium.otf');
	font-weight: 600;
	font-style: normal;
}

/* Gotham SemiBold Italic*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-MediumItalic.otf');
	font-weight: 600;
	font-style: italic;
}

/* Gotham SemiLight Italic*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-LightItalic.otf');
	font-weight: 350;
	font-style: italic;
}

/* Gotham Extra Light Italic*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-XLightItalic.otf');
	font-weight: 200;
	font-style: italic;
}

/* Gotham Extra Light*/
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-XLight.otf');
	font-weight: 200;
	font-style: normal;
}
