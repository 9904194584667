@import url('./application.css');

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(20px);
	background-color: rgba(5, 5, 5, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 31;
}

.close-button {
	position: absolute;
	top: 10px;
	right: 30px;
	cursor: pointer;
	font-size: 40px;
}