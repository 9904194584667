/* Normal Styles */

/* Inter Tight SuperLight*/
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-ExtraLight.ttf');
	font-weight: 200;
	font-style: normal;
}

/* Inter Tight SemiLight*/
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-Thin.ttf');
	font-weight: 350;
	font-style: normal;
}

/* Inter Tight Light*/
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-Light.ttf');
	font-weight: 300;
	font-style: normal;
}

/* Inter Tight Normal */
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-Regular.ttf');
	font-weight: 400;
	font-style: normal;
}

/* Inter Tight Medium */
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}

/* Inter Tight SemiBold*/
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-SemiBold.ttf');
	font-weight: 600;
	font-style: normal;
}

/* Inter Tight Bold */
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

/* Inter Tight ExtraBold */
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-ExtraBold.ttf');
	font-weight: 750;
	font-style: normal;
}

/* Inter Tight Black*/
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-Black.ttf');
	font-weight: 900;
	font-style: normal;
}

/* Italic Styles */

/* Inter Tight SuperLight*/
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-ExtraLightItalic.ttf');
	font-weight: 200;
	font-style: italic;
}

/* Inter Tight SemiLight*/
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-ThinItalic.ttf');
	font-weight: 350;
	font-style: italic;
}

/* Inter Tight Light*/
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-LightItalic.ttf');
	font-weight: 300;
	font-style: italic;
}

/* Inter Tight italic */
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-Italic.ttf');
	font-weight: 400;
	font-style: italic;
}

/* Inter Tight Medium */
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-MediumItalic.ttf');
	font-weight: 500;
	font-style: italic;
}

/* Inter Tight SemiBold*/
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-SemiBoldItalic.ttf');
	font-weight: 600;
	font-style: italic;
}

/* Inter Tight Bold */
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-BoldItalic.ttf');
	font-weight: 700;
	font-style: italic;
}

/* Inter Tight ExtraBold */
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-ExtraBoldItalic.ttf');
	font-weight: 750;
	font-style: italic;
}

/* Inter Tight Black*/
@font-face {
	font-family: 'Inter Tight';
	src: url('InterTight-BlackItalic.ttf');
	font-weight: 900;
	font-style: italic;
}