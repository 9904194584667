button {
	cursor: pointer;
	font-family: 'SegoeUI';
	line-height: 1.5;
	text-align: center;
	-webkit-user-select: none;
	user-select: none;
	white-space: nowrap;
	color: #e3e3e3;
	font-size: 1rem;
	font-weight: 510;
	border-radius: 5px;
	backdrop-filter: blur(3.5px);
	background-color: rgba(28, 9, 45, 0.9);
	border: none;
	padding: 13px;
	padding-left: 25px;
	padding-right: 25px;
	outline: none;
	transition: background-color 0.3s ease;
}

button:hover {
	background-color: rgba(28, 9, 45, 0.8);
}

.inverted-button {
	color: rgb(28, 9, 45);
	background-color: #e3e3e3;
	transition: background-color 0.3s ease;
}

.inverted-button:hover {
	background-color: #c7c7c7;
}