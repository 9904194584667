/* Define the spinning animation */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Spin animation */
.menu>li>a:hover .spin-icon {
    animation: spin 0.8s cubic-bezier(0.77, 0, 0.175, 1) 1;
    /* Spin once */
    animation-fill-mode: forwards
}

/* Basic Styles */
.menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu>li {
    position: relative;
    /* Ensure the dropdown is positioned relative to this item */
    display: inline-block;
}

.menu>li>a {
    text-decoration: none;
    padding: 10px 15px;
    display: block;
    color: #333;
    font-weight: 600;
}

/* Hide the dropdown initially */
.dropdown-menu {
    display: none;
    position: absolute;
    /* Absolute positioning to position it relative to the parent <li> */
    border-radius: 5px;
    border-color: #bfbfbf;
    border-width: 1px;
    border-style: solid;
    background-color: #ffffff;
    list-style: none;
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0;
    top: 100%;
    /* Position it below the trigger link */
    left: 0;
    width: 300px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    /* High z-index to ensure it appears on top of everything */
}

/* Dropdown menu links styling */
.dropdown-menu li {
    padding: 10px;
}

.dropdown-menu li a {
    text-decoration: none;
    color: #333;
}

/* Dropdown stays open when hovering over the trigger link or the dropdown itself */
.dropdown:hover .dropdown-menu,
.dropdown-menu:hover {
    display: block;
}

/* Hover effect for the dropdown items */
.dropdown-menu li:hover {
    background-color: #f1f1f1;
}