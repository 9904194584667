p {
	font-size: 17px;
}

a {
	text-decoration: none;
	color: black;
}

:is(h1, h2, h3, h4, h5, h6) {
	font-family: 'Inter Tight';
}